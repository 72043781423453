/* ==============================
GOOGLE FONT
===============================*/
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;700&family=Poppins:wght@300&display=swap');

* {
  margin: 0;
  padding: 0;
}



