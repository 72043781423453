@import url(https://fonts.googleapis.com/css2?family=Dosis:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;700&family=Poppins:wght@300&display=swap);
/* ==============================
GOOGLE FONT
===============================*/

* {
  margin: 0;
  padding: 0;
}




body {
   font-family: "Roboto", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
   font-family: "Dosis", sans-serif;
}

ul {
   list-style: none;
}

a,
a:hover,
a:focus,
a:active {
   text-decoration: none;
   color: inherit;
}

::-webkit-scrollbar {
   width: 7px;
   background-color: #dc3545;
}

::-webkit-scrollbar-thumb {
   background: #dc3545;
}

::-webkit-scrollbar-track {
   background-color: #e9edf0;
   border-left: 1px solid #ededed;
}

.content-body {
   margin-left: 90px;
}

.button {
   display: inline-block;
   border: none;
   outline: none;
   text-align: center;
   cursor: pointer;
   transition: 0.3s;
   -webkit-transition: 0.3s;
   -moz-transition: 0.3s;
   -ms-transition: 0.3s;
   -o-transition: 0.3s;
   border-radius: 50px;
   font-weight: 500;
}

.home-btn {
   border: 2px solid #dc3545;
   background: #dc35462a;
   color: white;
   padding: 12px 40px;
}

.home-btn:hover {
   background: white;
   color: #dc3545;
   border: 2px solid white;
}

.main-btn {
   background: #000;
   color: #fff;
   padding: 8px 20px;
   font-size: 15px;
}

.main-btn-alt {
   background: #dc3545;
   color: #fff;
   padding: 8px 20px;
   font-size: 15px;
}

.main-btn-lg {
   padding: 14px 45px;
   font-size: 17px;
}

.main-btn:hover {
   background: #dc3545;
   color: #fff;
}

.main-btn-alt:hover {
   background: #000;
   color: #fff;
}

.main-btn i {
   margin-left: 5px;
   transform: translateY(1px);
   transition: 0.3s;
   -webkit-transition: 0.3s;
   -moz-transition: 0.3s;
   -ms-transition: 0.3s;
   -o-transition: 0.3s;
   -webkit-transform: translateY(1px);
   -moz-transform: translateY(1px);
   -ms-transform: translateY(1px);
   -o-transform: translateY(1px);
}

.main-btn:hover i {
   margin-left: 10px;
}

.icon-btn {
   width: 35px;
   height: 35px;
   border-radius: 50%;
   line-height: 38px;
   text-align: center;
   color: #fff;
   font-size: 13px;
   background: rgba(0, 0, 0, 0.5);
}

.icon-btn:hover {
   background: rgba(0, 0, 0, 0.849);
}

.section-title {
   margin-bottom: 50px;
   text-align: center;
}

.section-title h5 {
   color: #dc3545;
   text-transform: uppercase;
   font-size: 18px;
   font-family: "Roboto", sans-serif;
   margin-bottom: 25px;
}

.section-title h3 {
   font-size: 40px;
   font-weight: 400;
   letter-spacing: 2px;
   text-transform: capitalize;
}

.sec-pad-top {
   padding-top: 100px;
}

.sec-pad-bottom {
   padding-bottom: 100px;
}

.sec-pad-top-sm {
   padding-top: 70px;
}

.sec-pad-bottom-sm {
   padding-bottom: 70px;
}
.social-icon {
  position: fixed;
  right: 30px;
  bottom: 0px;
  padding-bottom: 130px;
  z-index: 9;
}

.social-icon::before {
  content: '';
  position: absolute;
  left: 17px;
  bottom: 0;
  height: 120px;
  width: 2px;
  background: #dc3545;
}

.social-toggle {
  cursor: pointer;
  text-align: center;
  animation: tada 1.4s infinite;
  -webkit-animation: tada 1.4s infinite;
}

.social-toggle img {
  width: 100%;
}

@-webkit-keyframes tada {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  10%,
  20% {
    -webkit-transform: scale(0.9) rotate(-5deg);
            transform: scale(0.9) rotate(-5deg);
  }

  30%,
  50%,
  70%,
  90% {
    transform: scale(1.1) rotate(5deg);
    -webkit-transform: scale(1.1) rotate(5deg);
    -moz-transform: scale(1.1) rotate(5deg);
    -ms-transform: scale(1.1) rotate(5deg);
    -o-transform: scale(1.1) rotate(5deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale(1.1) rotate(-5deg);
            transform: scale(1.1) rotate(-5deg);
  }

  100% {
    -webkit-transform: scale(1) rotate(0);
            transform: scale(1) rotate(0);
  }
}

@keyframes tada {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  10%,
  20% {
    -webkit-transform: scale(0.9) rotate(-5deg);
            transform: scale(0.9) rotate(-5deg);
  }

  30%,
  50%,
  70%,
  90% {
    transform: scale(1.1) rotate(5deg);
    -webkit-transform: scale(1.1) rotate(5deg);
    -moz-transform: scale(1.1) rotate(5deg);
    -ms-transform: scale(1.1) rotate(5deg);
    -o-transform: scale(1.1) rotate(5deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale(1.1) rotate(-5deg);
            transform: scale(1.1) rotate(-5deg);
  }

  100% {
    -webkit-transform: scale(1) rotate(0);
            transform: scale(1) rotate(0);
  }
}

.social-icon .icon {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: transparent;
  margin-bottom: 20px;
  font-size: 20px;
  text-align: center;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.social-icon .icon:hover {
  -webkit-transform: translateY(-6px);
          transform: translateY(-6px);
  background: #dc3545;
  color: #fff;
}

@media (max-width: 991px) {

  .social-icon {
    padding-bottom: 0;
    bottom: 105px;
  }

  .social-icon::before {
    display: none;
  }

  .social-toggle {
    position: fixed;
    bottom: 60px;
    right: 28px;
    width: 35px;
    height: 35px;
    line-height: 35px;
  }

  .social-icon-wrapper {
    background: #dc3545;
    border-radius: 50px;
    height: 0;
    padding: 5px 0px;
    overflow: hidden;
    transition: 0.3s linear;
    -webkit-transition: 0.3s linear;
    -moz-transition: 0.3s linear;
    -ms-transition: 0.3s linear;
    -o-transition: 0.3s linear;
  }

  .social-icon-wrapper.open {
    height: 155px;
    transition: 0.3s linear;

  }

  .social-icon span:hover {
    transform: unset;
    color: #fff;
    -webkit-transform: unset;
    -moz-transform: unset;
    -ms-transform: unset;
    -o-transform: unset;
  }

}

@media (max-width: 767px) {
  .social-icon {
    right: 8px;
    bottom: 60px;
  }

  .social-toggle {
    position: fixed;
    bottom: 20px;
    right: 10px;
    width: 30px;
    height: 30px;
    line-height: 30px;
  }


}
