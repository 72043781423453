.social-icon {
  position: fixed;
  right: 30px;
  bottom: 0px;
  padding-bottom: 130px;
  z-index: 9;
}

.social-icon::before {
  content: '';
  position: absolute;
  left: 17px;
  bottom: 0;
  height: 120px;
  width: 2px;
  background: #dc3545;
}

.social-toggle {
  cursor: pointer;
  text-align: center;
  animation: tada 1.4s infinite;
  -webkit-animation: tada 1.4s infinite;
}

.social-toggle img {
  width: 100%;
}

@keyframes tada {
  0% {
    transform: scale(1);
  }

  10%,
  20% {
    transform: scale(0.9) rotate(-5deg);
  }

  30%,
  50%,
  70%,
  90% {
    transform: scale(1.1) rotate(5deg);
    -webkit-transform: scale(1.1) rotate(5deg);
    -moz-transform: scale(1.1) rotate(5deg);
    -ms-transform: scale(1.1) rotate(5deg);
    -o-transform: scale(1.1) rotate(5deg);
  }

  40%,
  60%,
  80% {
    transform: scale(1.1) rotate(-5deg);
  }

  100% {
    transform: scale(1) rotate(0);
  }
}

.social-icon .icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: transparent;
  margin-bottom: 20px;
  font-size: 20px;
  text-align: center;
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.social-icon .icon:hover {
  transform: translateY(-6px);
  background: #dc3545;
  color: #fff;
}

@media (max-width: 991px) {

  .social-icon {
    padding-bottom: 0;
    bottom: 105px;
  }

  .social-icon::before {
    display: none;
  }

  .social-toggle {
    position: fixed;
    bottom: 60px;
    right: 28px;
    width: 35px;
    height: 35px;
    line-height: 35px;
  }

  .social-icon-wrapper {
    background: #dc3545;
    border-radius: 50px;
    height: 0;
    padding: 5px 0px;
    overflow: hidden;
    transition: 0.3s linear;
    -webkit-transition: 0.3s linear;
    -moz-transition: 0.3s linear;
    -ms-transition: 0.3s linear;
    -o-transition: 0.3s linear;
  }

  .social-icon-wrapper.open {
    height: 155px;
    transition: 0.3s linear;

  }

  .social-icon span:hover {
    transform: unset;
    color: #fff;
    -webkit-transform: unset;
    -moz-transform: unset;
    -ms-transform: unset;
    -o-transform: unset;
  }

}

@media (max-width: 767px) {
  .social-icon {
    right: 8px;
    bottom: 60px;
  }

  .social-toggle {
    position: fixed;
    bottom: 20px;
    right: 10px;
    width: 30px;
    height: 30px;
    line-height: 30px;
  }


}