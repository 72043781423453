body {
   font-family: "Roboto", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
   font-family: "Dosis", sans-serif;
}

ul {
   list-style: none;
}

a,
a:hover,
a:focus,
a:active {
   text-decoration: none;
   color: inherit;
}

::-webkit-scrollbar {
   width: 7px;
   background-color: #dc3545;
}

::-webkit-scrollbar-thumb {
   background: #dc3545;
}

::-webkit-scrollbar-track {
   background-color: #e9edf0;
   border-left: 1px solid #ededed;
}

.content-body {
   margin-left: 90px;
}

.button {
   display: inline-block;
   border: none;
   outline: none;
   text-align: center;
   cursor: pointer;
   transition: 0.3s;
   -webkit-transition: 0.3s;
   -moz-transition: 0.3s;
   -ms-transition: 0.3s;
   -o-transition: 0.3s;
   border-radius: 50px;
   font-weight: 500;
}

.home-btn {
   border: 2px solid #dc3545;
   background: #dc35462a;
   color: white;
   padding: 12px 40px;
}

.home-btn:hover {
   background: white;
   color: #dc3545;
   border: 2px solid white;
}

.main-btn {
   background: #000;
   color: #fff;
   padding: 8px 20px;
   font-size: 15px;
}

.main-btn-alt {
   background: #dc3545;
   color: #fff;
   padding: 8px 20px;
   font-size: 15px;
}

.main-btn-lg {
   padding: 14px 45px;
   font-size: 17px;
}

.main-btn:hover {
   background: #dc3545;
   color: #fff;
}

.main-btn-alt:hover {
   background: #000;
   color: #fff;
}

.main-btn i {
   margin-left: 5px;
   transform: translateY(1px);
   transition: 0.3s;
   -webkit-transition: 0.3s;
   -moz-transition: 0.3s;
   -ms-transition: 0.3s;
   -o-transition: 0.3s;
   -webkit-transform: translateY(1px);
   -moz-transform: translateY(1px);
   -ms-transform: translateY(1px);
   -o-transform: translateY(1px);
}

.main-btn:hover i {
   margin-left: 10px;
}

.icon-btn {
   width: 35px;
   height: 35px;
   border-radius: 50%;
   line-height: 38px;
   text-align: center;
   color: #fff;
   font-size: 13px;
   background: rgba(0, 0, 0, 0.5);
}

.icon-btn:hover {
   background: rgba(0, 0, 0, 0.849);
}

.section-title {
   margin-bottom: 50px;
   text-align: center;
}

.section-title h5 {
   color: #dc3545;
   text-transform: uppercase;
   font-size: 18px;
   font-family: "Roboto", sans-serif;
   margin-bottom: 25px;
}

.section-title h3 {
   font-size: 40px;
   font-weight: 400;
   letter-spacing: 2px;
   text-transform: capitalize;
}

.sec-pad-top {
   padding-top: 100px;
}

.sec-pad-bottom {
   padding-bottom: 100px;
}

.sec-pad-top-sm {
   padding-top: 70px;
}

.sec-pad-bottom-sm {
   padding-bottom: 70px;
}